import { Button } from '@/components/Button';
import Layout from '@/components/Layout';
import SectionTitle from '@/components/SectionTitle';
import Video from '@/components/Video';
import { useBreakpoints } from '@/hooks/useBreakpoints';
import { JoinDiscord } from '@/sections/Kargo/components/join-discord';
import ArtefactIcon from '@/static/assets/icons/KargoPage/icn-artefact.svg';
import BallIcon from '@/static/assets/icons/KargoPage/icn-beachball.svg';
import BranchIcon from '@/static/assets/icons/KargoPage/icn-branch.svg';
import CascadeIcon from '@/static/assets/icons/KargoPage/icn-cascade.svg';
import EnvsIcon from '@/static/assets/icons/KargoPage/icn-environments.svg';
import GithubIcon from '@/static/assets/icons/KargoPage/icn-github.svg';
import JumpUpIcon from '@/static/assets/icons/KargoPage/icn-jumpup.svg';
import PointerIcon from '@/static/assets/icons/KargoPage/icn-pointer.svg';
import SnowflakeIcon from '@/static/assets/icons/KargoPage/icn-snowflake.svg';
import SquareStripesIcon from '@/static/assets/icons/KargoPage/icn-squarestripes.svg';
import KargoDiagramAfterImg from '@/static/assets/icons/KargoPage/kargo-diagram-after.svg';
import KargoDiagramBeforeImg from '@/static/assets/icons/KargoPage/kargo-diagram-before.svg';
import KargoIcon from '@/static/assets/icons/KargoPage/kargo-logo-horizontal.svg';
import './what-is-kargo.scss';
import { KARGO_DOCS_URL, KARGO_GITHUB_URL } from '@/utils/externalLinks';
import { seoConfiguration } from '@/utils/seo-config';
import { KARGO_BETA } from '@/utils/urlUi';

const seo = {
  header: 'What is Kargo?',
  ...seoConfiguration['what-is-kargo']
};

const WhatIsKargoPage = () => {
  const { isBreakpointRange } = useBreakpoints();
  const isTablet = isBreakpointRange('tablet');

  return (
    <Layout {...seo}>
      <div className="kargo-page">
        <div className="kargo-logo">
          <KargoIcon />
        </div>
        <h3>
        Continuous Promotion with GitOps
        </h3>
        <Buttons />
        <JoinDiscord className="join-kargo-discord" />
        <div className="video-wrapper">
          <Video
            videoSrcURL="https://www.youtube.com/embed/0B_JODxyK0w"
            videoTitle="Kargo - Multi-Stage Deployment Pipelines using GitOps - Jesse Suen / Kent Rancourt"
          />
        </div>
        <h3>
        Kargo provides stage-to-stage promotion using GitOps principles without bespoke automation or relying on CI pipelines.
        </h3>

        <div className="kargo-diagram">
          <div
            className={`kargo-diagram__col${
              isTablet ? ' no-global-padding' : ''
            }`}
          >
            <div className="kargo-diagram__title">
              <h4>Typical CI Environment Promotion Today</h4>
            </div>
            <KargoDiagramBeforeImg />
          </div>
          <div className="kargo-diagram__sep" />
          <div className="kargo-diagram__col">
            <div className="kargo-diagram__title">
              <h4>With Kargo</h4>
            </div>
            <KargoDiagramAfterImg />
          </div>
        </div>
        <h2>Key Benefits</h2>
        <h3>
          <li>Flexible promotion pipeline for multiple stages</li>
          <li>Visualizing changes across all environments</li>
          <li>Intuitive UX abstracting the nuances of GitOps</li>
          <li>Safer deployments with processes and guardrails</li>
          <li>Testing and verification between stages</li>
        </h3>
        <SectionTitle>First-class GitOps Support</SectionTitle>
        <div className="features">
          <div className="features__item">
            <CascadeIcon />
            <p>
            Promote configuration the same way as container images
            </p>
          </div>
          <div className="features__item">
            <BallIcon />
            <p>
              State-driven promotion process by subscribing to git artifacts,
              image updates, Helm chart updates, and more
            </p>
          </div>
          <div className="features__item">
            <BranchIcon />
            <p>
              Built upon GitOps principles, integrates with existing Kubernetes
              deployment technologies
            </p>
          </div>
        </div>
        <SectionTitle>Deploy with Tools Made for the Purpose</SectionTitle>
        <div className="features">
          <div className="features__item">
          <JumpUpIcon />
          <p>Introduce progressive deployment strategies across stages</p>
          </div>
          <div className="features__item">
          <SnowflakeIcon />
          <p>Stop relying on snowflake scripts inside your CI tools</p>
          </div>
          <div className="features__item">
          <ArtefactIcon />
            <p>
              Stop using your CI-generated, pre-CD artifacts as the source of
              truth
            </p>
          </div>
        </div>
        <SectionTitle>Improve Developer Experience</SectionTitle>
        <div className="features">
          <div className="features__item">
          <SquareStripesIcon />
            <p>
              Introduce guardrails for developers and provide deep insights to
              enable developers to perform self-service promotion
            </p>
          </div>
          <div className="features__item">
            <EnvsIcon />
            <p>
            Frictionless promotion and rollback of artifacts including container images, Kubernetes manifests, and Helm charts
            </p>
          </div>
          <div className="features__item">
          <PointerIcon />
            <p>
              Introduce UI for a higher abstraction level necessary for
              businesses to deliver applications quickly
            </p>
          </div>
        </div>
        <hr className="divider" />
        <Buttons />
      </div>
    </Layout>
  );
};

const Buttons = () => (
  <div className="kargo-buttons">
    <Button additionalClass="primary-button" link={KARGO_BETA}>
      Kargo Enterprise
    </Button>
    <Button
      additionalClass="default-button"
      link={KARGO_DOCS_URL}
      isExternalLink
    >
      Read the Docs
    </Button>
    <Button
      additionalClass="default-button"
      link={KARGO_GITHUB_URL}
      isExternalLink
    >
      <>
        <GithubIcon />
        <span>Star on GitHub</span>
      </>
    </Button>
  </div>
);

export default WhatIsKargoPage;
