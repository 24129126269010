import { PropsWithChildren } from 'react';
import './SectionTitle.scss';

const SectionTitle = ({ children }: PropsWithChildren<{}>) => (
  <h4 className="section-title">
    <span>{children}</span>
  </h4>
);

export default SectionTitle;
