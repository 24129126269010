import React from 'react';

import './Video.scss';

type Props = {
  videoSrcURL: string;
  videoTitle: string;
};

const Video = ({ videoSrcURL, videoTitle }: Props) => (
  <div className="video">
    <iframe
      src={videoSrcURL}
      title={videoTitle}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      allowFullScreen
    />
  </div>
);
export default Video;
